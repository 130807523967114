html {
  box-sizing: border-box;
  overflow: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f2f2f2;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
}

a {
  text-decoration: none !important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
